<template>
<div class='general'>
    <smallbutton :toplabel="title" @click="Start()"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form >
                <div class="rightjustified">
                    <smallbutton @click="close()" toplabel='X' buttonclass="buttonclose" />
                </div>
                <label class="titlelabel">{{title}}</label>

                <!-- select one -->
                <div class="entry" v-if="!inEdit">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Select Alert Template to Edit</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="selectedItem" @change="SelectMyItem">
                        <option v-for="item in itemList" :key="item.id" :value="item.id" >
                            {{item.name}}
                        </option>
                    </select>
                </div>

                <div>
                    <label v-if="pleaseWait" class="pleasewait">Please Wait...</label>
                </div>

                <div v-if="!inEdit">
                    <smallbutton  @click="update()" toplabel="Update" :disabled="!currentIsActive"/>
                    <smallbutton  @click="add()" toplabel="Add" />
                    
                    <smallbutton  @click="activate()" :toplabel="activateText" :disabled="selectedItem<=0" />
                    <!-- <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close Manager"/> -->
                </div>
                <div v-if="inEdit" class="mainform">
                    <div class="col">
                        <formentry label="Name" isrequired='True' v-model="currentItem.name" />
                        <!-- <formentry label="Title" v-model="currentItem.alertTemplateText" />
                        <formentry label="Details"  v-model="currentItem.alertTemplateText2" /> -->
                        <div class="entry">    
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Title</label>
                            </div>
                            
                            <textarea class="entrymulti"   v-model="currentItem.alertTemplateText" rows="2" cols="20"/>
                        </div>
                        <div class="entry">    
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Details</label>
                            </div>
                            
                            <textarea class="entrymulti"   v-model="currentItem.alertTemplateText2" rows="2" cols="20"/>
                        </div>
                        
                        <formentry  label="Duration In Hours" inputtype="number"  v-model="currentItem.durationInHours" />
                        
                        <hr>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Applies to Location</label>
                            </div>
                            <select name="" id="" class='entrySelect' v-model="currentItem.locationId">
                                <option v-for="location in locations" :key="location.id" :value="location.id" >
                                    {{location.name}}
                                </option>
                            </select>
                        </div>
                        

                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Can Be Dismissed</label>
                            </div>
                            <div class="entrycheck">
                                <input  type="checkbox"  v-model="currentItem.canBeDismissed" />
                            </div>
                            
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Effective Immediately</label>
                            </div>
                            <div class="entrycheck">
                            <input type="radio" value="immediately" v-model="effective" />
                            </div>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Effective Next Business Day</label>
                            </div>
                            <div class="entrycheck">
                            <input type="radio" value="nextBusinessDay" v-model="effective" />
                            </div>
                        </div>

                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Effective Next Calendar Day</label>
                            </div>
                            
                            <div class="entrycheck">
                            <input type="radio" value="nextCalendarDay" v-model="effective" />
                            </div>
                        </div>
                        
                    
                    </div>
  
                </div>
                <div v-if="inEdit">
                    <smallbutton  @click="save()" toplabel="Save" />
                    <smallbutton  @click="cancel()" toplabel="Cancel" />
                </div>

                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
          </form>

            
            <actionmodal ref="activateModal"> 
                <template v-slot:body>
                    <label >Do you want to {{activateText}} the selected Item?</label>
                    <div>
                        <smallbutton   @click="finishActivate()" :toplabel="activateText"/>
                        <smallbutton  @click="$refs.activateModal.closeModal()" toplabel="Cancel"/>
                    </div>
                </template>
            </actionmodal>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
import formentrymulti from '../controls/entrymulti.vue'
export default {
    name:'badgealerttemplateadmin',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        formentrymulti
    },
    props:{

        
    },
    mixins:[datamethods,format],
    data(){
        return{
            title:'Badge Alert Templates',
            inEdit:false,
            selectedItem:-1,
            currentItem:{},
            message:'',
            itemList:[],
            departments:[],
            locations:[],
            personRoleList:[],

            pleaseWait:false,

            effective:''
        }
    },
    computed:{
        ...mapFields([
            'loggedIn',
        ]),
        activateText(){
            if(this.currentItem.isActive){return "Deactivate"}
            else{return "Activate"}
        },
        currentIsActive(){
            return this.currentItem.isActive
        }
    },
    methods:{
        /* handleAuthResponse(){
            if(this.message.includes('You are not authorized')){this.loggedIn=false}
        }, */
        close(){
            this.$emit('refresh')
            this.$refs.mymodal.closeModal()
        },
       


      
        async Start(){
            this.GetItemList();
            this.getLocations();
            this.$refs.mymodal.openModal()
        },
        update(){
            if(this.selectedItem<=0){return}

            this.SelectMyItem()
            //start edit
            this.inEdit=true
        },
        add(){
            this.selectedItem=-1
            this.GetNewItem()
            //start edit
            this.inEdit=true
        },
        
        activate(){
            this.$refs.activateModal.openModal()
        },
        finishActivate(){
            this.currentItem.isActive = !this.currentItem.isActive
            this.currentItem.name.replace(' (Inactive)','')
            this.save()
            this.$refs.activateModal.closeModal()
        },
        async save(){
           //fix effective
           switch (this.effective) {
               case 'immediately':
                    this.currentItem.effectiveImmediately=true;
                    this.currentItem.effectiveNextBusinessDay=false;
                    this.currentItem.effectiveNextCalendarDay=false;
                   break;
                case 'nextBusinessDay':
                    this.currentItem.effectiveImmediately=false;
                    this.currentItem.effectiveNextBusinessDay=true;
                    this.currentItem.effectiveNextCalendarDay=false;
                   break;
                case 'nextCalendarDay':
                    this.currentItem.effectiveImmediately=false;
                    this.currentItem.effectiveNextBusinessDay=false;
                    this.currentItem.effectiveNextCalendarDay=true;
                   break;
               default:
                   break;
           }
       
           
            let req = {
                BadgeAlertTemplate:this.currentItem
            }
            this.message=""
            this.pleaseWait=true;
            var response = await this.callAPI(req,'savebadgealerttemplate')
            if(response.Successful){
                this.selectedItem=response.ResponseInteger
                this.inEdit=false
                this.GetItemList()
            }else{
                this.message = response.Message
                //this.handleAuthResponse()
            }
            this.pleaseWait=false;
        },
        cancel(){
            this.message=''
            this.inEdit=false
        },
        async SelectMyItem(){
            var currentid = this.selectedItem
            var sel
            this.itemList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let a=arr[index]
                if(a.id==currentid){
                    sel=a
                }
            }     
            this.currentItem=sel

            //set effective
            if(this.currentItem.effectiveImmediately){
                this.effective="immediately"
            }else if(this.currentItem.effectiveNextBusinessDay){
                this.effective ="nextBusinessDay"
            }else if(this.currentItem.effectiveNextCalendarDay){
                this.effective="nextCalendarDay"
            }

        },
        GetNewItem(){
            this.currentItem={
                id:-1,
                name:null,
                alertTemplateText:null,
                alertTemplateText2:null,
                durationInHours:0,
                canBeDismissed:false,
                effectiveImmediately:false,
                effectiveNextBusinessDay:false,
                effectiveNextCalendarDay:false,
                isActive:true,
                locationId:-1,
            }
        },
        
        async GetItemList(){
            this.message=""
            this.pleaseWait=true;
            //call api
            var response = await this.callAPI(null,'badgealerttemplatelist')
            //handle response
            if(response.Successful){
                this.itemList=response.BadgeAlertTemplateList

                //show which are inactive
                this.itemList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let a=arr[index]
                    if(!a.isActive){
                        a.name = a.name + " (Inactive)"
                    }
                } 

            }else{
                this.message = response.Message
                //this.handleAuthResponse()
            }
            this.pleaseWait=false;
        },
        
       
        async getLocations(){
            this.message=""
            //call api
            var response = await this.callAPI(null,'locationlist')
            //handle response
            this.tmpresp = response
            if(response.Successful){
                this.locations=response.institutionLocationList
            }else{
                this.message = response.Message
               // this.handleAuthResponse()
            }
        },
    },

}
</script>

<style>
.mainform{
    width:100%;
    display:grid;
    grid-auto-columns: 1fr;
}
.general{
    font-size:medium;
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.imgbutton{
    background-color:var(--primary-color);
    color:var(--light-text-color);
    border: none;
    border-radius:10px;
    min-width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
  text-decoration: none;
  position:relative;
}
.imgbutton:hover{
    background-color:var(--highlight-color);
  cursor:pointer;
}
.icondiv{
    background-color:blue;
    background:transparent;
}
.icon{
    background:transparent;
    max-width: 250px;;
}
.col{
    padding:20px;
    width:100%;
}
.entrycheck{
    text-align: left;
    width:100%;
}
.entrySelect{
    min-width: 220px;
}
form{
    width:100%;
}
.multitext{
    
}
.entrymulti{
    visibility: visible;
    font-size:inherit;
    margin:0px 5px 0px 5px;
    
    text-align:left;
    resize:none;
    min-height:50px;
    width:100%;
}
</style>





























