<template>
<div class='general'>
    <smallbutton toplabel="Badge Designs" @click="Start()"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodalwide ref="mymodal"> 
        <template v-slot:body>
            <form >
                <div class="rightjustified">
                    <smallbutton @click="close()" toplabel='X' buttonclass="buttonclose" />
                </div>
                <label class="titlelabel">Manage Badge Designs</label>

                <!-- select one -->
                <div class="entry" v-if="!inEdit">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Select Design to Edit</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="selectedDesign" @change="SelectDesign">
                        <option v-for="design in designList" :key="design.id" :value="design.id" >
                            {{design.name}}
                        </option>
                    </select>
                </div>

                <div>
                    <label v-if="pleaseWait" class="pleasewait">Please Wait...</label>
                </div>

                <div v-if="!inEdit">
                    <smallbutton  @click="update()" toplabel="Update" />
                    <smallbutton  @click="add()" toplabel="Add" />
                    <smallbutton  @click="activate()" :toplabel="activateText" :disabled="selectedDesign<=0" />
                    
                </div>
                <div v-if="inEdit" class="mainform">
                    <div class="col">
                        <formentry label="Name" isrequired='True' v-model="design.name" />
                        <formentry label="Main Page Title" v-model="design.mainPageTitle" />
                        <formentry label="Information Page Title" v-model="design.informationPageTitle" />
                        <hr>

                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Set as Institution Default</label>
                            </div>
                            <div class="entrycheck">
                                <input  type="checkbox"  v-model="isDefault_Institution" />
                            </div>
                        </div>


                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Applies to Location</label>
                            </div>
                            <select name="" id="" class='entrySelect' v-model="design.appliesToLocationId" @change="selectLocation()">
                                <option v-for="location in locations" :key="location.id" :value="location.id" >
                                    {{location.name}}
                                </option>
                            </select>
                        </div>

                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Set as Location Default</label>
                            </div>
                            <div class="entrycheck">
                                <input  type="checkbox"  v-model="isDefault_Location" />
                            </div>
                        </div>

                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Applies to Department</label>
                            </div>
                            <select name="" id="" class='entrySelect' v-model="design.appliesToDepartmentId" >
                                <option v-for="department in departments" :key="department.id" :value="department.id" >
                                    {{department.name}}
                                </option>
                            </select>
                        </div>

                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Set as Department Default</label>
                            </div>
                            <div class="entrycheck">
                                <input  type="checkbox"  v-model="isDefault_Department" />
                            </div>
                        </div>



                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Applies to Status</label>
                            </div>
                            <select name="" id="" class='entrySelect' v-model="design.appliesToPersonStatusId" >
                                <option v-for="status in personStatusList" :key="status.id" :value="status.id" >
                                    {{status.name}}
                                </option>
                            </select>
                        </div>

                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Applies to All Staff</label>
                            </div>
                            <div class="entrycheck">
                                <input  type="checkbox"  v-model="design.appliesToAllStaff" />
                            </div>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Applies to All Students</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="design.appliesToAllStudents" />
                            </div>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Applies to All Visitors</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="design.appliesToAllVisitors" />
                            </div>
                        </div>

                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Effective Date</label>
                            </div>
                            <input type="date" class="entrySelect"  v-model="design.effectiveDate" />
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Expiration Date</label>
                            </div>
                            <input type="date" class="entrySelect"  v-model="design.expirationDate" />
                        </div>
                        <hr>
                         <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Show Vaccine Card Icon</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="design.showVaccineCardIcon" />
                            </div>
                        </div>

                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Show License Icon</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="design.showLicenseIcon" />
                            </div>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Capture Person</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="design.capturePersonImage" />
                            </div>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Capture Vaccine Card</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="design.captureVaccineCard" />
                            </div>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Capture License Front</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="design.captureLicenseFront" />
                            </div>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Capture License Back</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="design.captureLicenseBack" />
                            </div>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Allow Replace Image</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="design.allowReplaceImage" />
                            </div>
                        </div>
                        

                        
                        
                    </div>
                    
                    <div class="col">
                        <!-- icon -->
                        <div class="icondiv">
                            <img id="myimg" :src="getIconFile()" class="icon" crossorigin="anonymous"/>
                        </div>

                        <formentry label="Logo/Icon File Name" v-model="design.badgeIcon" />
                        <imagecapture ref="iconimg"  buttonClass="imgbutton" :resizeImages="resizeImages" :showImage="showImageOnCapture" :allowFileSelect="allowFileSelect" :allowCapture="allowCapture" :fileSelectText="fileSelectText" :captureText="captureText" @gotimage="gotImage"/>

                        <!-- footer -->
                        <div class="icondiv">
                            <img id="myimg_footer" :src="getIconFile_footer()" class="icon" crossorigin="anonymous"/>
                        </div>
                        <formentry label="Footer/Icon File Name" v-model="design.badgeIcon_Footer" />
                        <imagecapture ref="iconimg_footer"  buttonClass="imgbutton" :resizeImages="resizeImages" :showImage="showImageOnCapture" :allowFileSelect="allowFileSelect" :allowCapture="allowCapture" :fileSelectText="fileSelectText_footer" :captureText="captureText_footer" @gotimage="gotImage_footer"/>



                        <hr>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Use Background Image</label>
                            </div>
                            <div class="entrycheck">
                                <input type="checkbox"  v-model="useBackgroundImage" />
                            </div>
                        </div>
                        <div v-if="useBackgroundImage">
                            <formentry label="Background File Name" v-model="design.badgeBackground" />
                            <imagecapture   buttonClass="imgbutton" :allowFileSelect="allowFileSelect" :allowCapture="allowCapture" :resizeImages="resizeImages"  :fileSelectText="fileSelectText_bg" :captureText="captureText_bg" @gotimage="gotImage_bg"/>
                        </div>
                        <hr>
                        <entrycolor label="Title Text Color" v-model="design.titleTextColor" @selectcolor="selectcolor_title()"/>
                        <colorpicker ref="cpicker_title"  @selectcolor="gotcolor_title"/>

                        <entrycolor label="Main Text Color" v-model="design.mainTextColor" @selectcolor="selectcolor_main()"/>
                        <colorpicker ref="cpicker_main"  @selectcolor="gotcolor_main"/>

                        <entrycolor label="Menu Text Color" v-model="design.menuTextColor" @selectcolor="selectcolor_menu()"/>
                        <colorpicker ref="cpicker_menu"  @selectcolor="gotcolor_menu"/>

                        <entrycolor label="Title Background Color" v-model="design.titleBackgroundColor" @selectcolor="selectcolor_titleBackground()"/>
                        <colorpicker ref="cpicker_titleBackground"  @selectcolor="gotcolor_titleBackground"/>

                        <entrycolor label="Main Background Color" v-model="design.mainBackgroundColor" @selectcolor="selectcolor_mainBackground()"/>
                        <colorpicker ref="cpicker_mainBackground"  @selectcolor="gotcolor_mainBackground"/>
                        
                    </div>

         
                    
                </div>
                <div v-if="inEdit">
                    <smallbutton  @click="save()" toplabel="Save" />
                    <smallbutton  @click="cancel()" toplabel="Cancel" />
                </div>

    
                
                
                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
          </form>

            
            <actionmodal ref="activateModal"> 
                <template v-slot:body>
                    <label >Do you want to {{activateText}} the selected Design?</label>
                    <div>
                        <smallbutton   @click="finishActivate()" :toplabel="activateText"/>
                        <smallbutton  @click="$refs.activateModal.closeModal()" toplabel="Cancel"/>
                    </div>
                </template>
            </actionmodal>
        </template>
      </actionmodalwide>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import actionmodalwide from '../controls/actionmodalwide.vue'
import formentry from '../controls/entry.vue'
import imagecapture from '../controls/ImageCapture.vue'
import linkbutton from '../controls/linkbutton.vue'
import colorpicker from '../controls/colorpicker.vue'
import entrycolor from '../controls/entrycolor.vue'
export default {
    name:'newbadgedesign',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        imagecapture,
        linkbutton,
        colorpicker,
        entrycolor,
        actionmodalwide
    },
    props:{

        
    },
    mixins:[datamethods,format],
    data(){
        return{
            useBackgroundImage:false,

            imageBasePath:'',
            inEdit:false,
            selectedDesign:-1,
            design:{},
            message:'',
            designList:[],
            locations:[],
            classes:[],
            departments:[],
            personstatus:[],

            allowFileSelect:true,
            allowCapture:false,
            fileSelectText:"Upload Logo File",
            captureText:"Take Logo Picture",
            showImageOnCapture:false,
            resizeImages:false,
            capturedImage:null,
            capturedImageURL:null,

            fileSelectText_bg:"Upload Background File",
            captureText_bg:"Take Background Picture",
            capturedImage_bg:null,
            capturedImageURL_bg:null,

            fileSelectText_footer:"Upload Footer Logo File",
            captureText_footer:"Take Footer Logo Picture",
            capturedImage_footer:null,
            capturedImageURL_footer:null,

            pleaseWait:false,

            isDefault_Location:false,
            isDefault_Institution:false,
            isDefault_Department:false
        }
    },
    computed:{
        activateText(){
            if(this.design.isActive){return "Deactivate"}
            else{return "Activate"}
        }
    },
    methods:{
        close(){
            this.$emit('refresh')
            this.$refs.mymodal.closeModal()
        },
        selectcolor_title(){
            var img = document.getElementById('myimg')
            this.$refs.cpicker_title.getcolors(img)
            this.$refs.cpicker_title.openModal()
        },
        gotcolor_title(color){
            this.design.titleTextColor=color
        },

        selectcolor_main(){
            var img = document.getElementById('myimg')
            this.$refs.cpicker_main.getcolors(img)
            this.$refs.cpicker_main.openModal()
        },
        gotcolor_main(color){
            this.design.mainTextColor=color
        },

        selectcolor_menu(){
            var img = document.getElementById('myimg')
            this.$refs.cpicker_menu.getcolors(img)
            this.$refs.cpicker_menu.openModal()
        },
        gotcolor_menu(color){
            this.design.menuTextColor=color
        },

        selectcolor_titleBackground(){
            var img = document.getElementById('myimg')
            this.$refs.cpicker_titleBackground.getcolors(img)
            this.$refs.cpicker_titleBackground.openModal()
        },
        gotcolor_titleBackground(color){
            this.design.titleBackgroundColor=color
        },

        selectcolor_mainBackground(){
            var img = document.getElementById('myimg')
            this.$refs.cpicker_mainBackground.getcolors(img)
            this.$refs.cpicker_mainBackground.openModal()
        },
        gotcolor_mainBackground(color){
            this.design.mainBackgroundColor=color
        },


        async getImageBasePath(){
            //call api
            this.message=""
            var response = await this.callAPI(null,'imagepath_api')
            //handle response
            if(response.Successful){
                this.imageBasePath = window.VUE_CONFIG_API  + response.StringResponse
            }else{
                this.message = response.Message
            }
        },
        async Start(){
            this.GetDesigns();
            this.getLocations();
            this.getPersonStatusList();
            this.getDepartments();
            this.getImageBasePath();
            //this.selectedDesign=1
            //this.SelectDesign()
            this.$refs.mymodal.openModal()
        },
        update(){
            if(this.selectedDesign<=0){return}

            this.SelectDesign()
            //start edit
            this.inEdit=true
        },
        add(){
            this.selectedDesign=-1
            this.SelectDesign()
            //start edit
            this.inEdit=true
        },
        activate(){
            this.$refs.activateModal.openModal()
        },
        finishActivate(){
            this.design.isActive = !this.design.isActive
            this.save()
            this.$refs.activateModal.closeModal()
        },
        async save(){
            //if no backgroudn, clear it
            if(!this.useBackgroundImage){
                this.design.badgeBackground=null
            }

            let req = {
                badgeDesign:this.design
            }
            this.message=""
            this.pleaseWait=true;
            var response = await this.callAPI(req,'savebadgedesign')
            if(response.Successful){
                this.selectedDesign=response.ResponseInteger

                this.saveDefaults()

                this.inEdit=false
                this.GetDesigns()
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        async saveDefaults(){
            if(this.isDefault_Institution || this.isDefault_Location || this.isDefault_Department){
                let req = {
                    badgeDesignId:this.selectedDesign,
                    defaultForInstitution:this.isDefault_Institution,
                    defaultForLocation:this.isDefault_Location ? this.design.appliesToLocationId : -1,
                    defaultForDepartment:this.isDefault_Department ? this.design.appliesToDepartmentId : -1
                }
                this.message=""
                this.pleaseWait=true;
                var response = await this.callAPI(req,'badgedesign_setasdefault')
                if(response.Successful){
                    
                }else{
                    this.message = response.Message
                }
            }


        },
        cancel(){
            this.message=''
            this.inEdit=false
        },
        async SelectDesign(){
            this.message=""
            let req = {
                id:this.selectedDesign
            }
            //call api
            var response = await this.callAPI(req,'badgedesign')
            //handle response
            if(response.Successful){
                this.design=response.BadgeDesign
                if(this.design.badgeBackground!=null){
                    this.useBackgroundImage=true
                }
                //defaults
                if(this.design.appliesToDepartmentId==0){this.design.appliesToDepartmentId=-1}
                if(this.design.appliesToPersonStatusId==0){this.design.appliesToPersonStatusId=-1}

                //format dates
                this.design.effectiveDate = this.$options.filters.formatDateForInput(this.design.effectiveDate)
                this.design.expirationDate = this.$options.filters.formatDateForInput(this.design.expirationDate)
                
                this.isDefault_Location=false
                this.isDefault_Institution=false
                this.isDefault_Department=false
                
            }else{
                this.message = response.Message
            }
        },
        async GetDesigns(){
            this.message=""
            this.pleaseWait=true;
            //call api
            var response = await this.callAPI(null,'badgedesignlist')
            //handle response
            if(response.Successful){
                this.designList=response.BadgeDesignList

                //show which are inactive
                this.designList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let a=arr[index]
                    if(!a.isActive){
                        a.name = a.name + " (Inactive)"
                    }
                } 

            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        
        async getLocations(){
            this.message=""
            //call api
            var response = await this.callAPI(null,'locationlist')
            //handle response
            this.tmpresp = response
            if(response.Successful){
                this.locations=response.institutionLocationList
            }else{
                this.message = response.Message
            }
        },
        async getPersonStatusList(){
            this.message=""
            //call api
            var response = await this.callAPI(null,'personstatuslist')
            //handle response
            this.tmpresp = response
            if(response.Successful){
                this.personStatusList=response.PersonStatusList
            }else{
                this.message = response.Message
            }
        },
        async getDepartments(){
            this.message=""
            //call api
            var response = await this.callAPI(null,'departmentlist')
            //handle response
            this.tmpresp = response
            if(response.Successful){
                this.departments=response.DepartmentList
            }else{
                this.message = response.Message
            }
        },
        getIconFile(){
            return this.imageBasePath + this.translatefilename(this.design.badgeIcon)
        },
        getIconFile_footer(){
            return this.imageBasePath + this.translatefilename(this.design.badgeIcon_Footer)
        },
        translatefilename(filename){
            if(filename==null){return}
            let index = filename.lastIndexOf(".")
            let name = filename.substring(0,index)
            let ext = filename.substring(index +1)
            return name + "/" + ext
        },
        gotImage(file,url){
            
            this.capturedImage=file
            this.capturedImageURL=url
            this.design.badgeIcon=file.name
            this.storeImage("logo", file, file.name)
        },
        gotImage_bg(file,url){
            this.capturedImage_bg=file
            this.capturedImageURL_bg=url
            this.storeImage("background", file, file.name)
        },
        gotImage_footer(file,url){
            console.log('foot img')
            this.capturedImage_footer=file
            this.capturedImageURL_footer=url
            this.storeImage("footer", file, file.name)
        },
        async storeImage(filename,file, name){
            var postParams = new FormData();

            postParams.append(filename,file,name);

            var response = await this.callAPI(postParams,'saveimage_logo')
            //handle response
            if(response.Successful){
                if(filename=='logo'){
                    this.design.badgeIcon=name
                }else if(filename=='background'){
                    this.design.badgeBackground=name
                }else if(filename=='footer'){
                    this.design.badgeIcon_Footer=name
                }
                
            }else{
                this.message = response.Message
            }
        },
    },
    mounted(){
       
        
        
    }
}
</script>

<style>
.mainform{
    display:grid;
    grid-template-columns:1fr 1fr;
    
}
.general{
    font-size:medium;
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.imgbutton{
    background-color:var(--primary-color);
    color:var(--light-text-color);
    border: none;
    border-radius:10px;
    min-width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
  text-decoration: none;
  position:relative;
}
.imgbutton:hover{
    background-color:var(--highlight-color);
  cursor:pointer;
}
.icondiv{
    background-color:blue;
    background:transparent;
}
.icon{
    background:transparent;
    max-width: 250px;;
}
.col{
    padding:20px;
}
.entrycheck{
    text-align: left;
    width:100%;
}
</style>





























