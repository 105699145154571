<template>
  <actionmodalwide ref="mymodal" > 
        <template v-slot:body>
            <div class="fullheight">
                <div class="rightjustified">
                    <smallbutton @click="$refs.mymodal.closeModal()" toplabel='X' buttonclass="buttonclose" />
                </div>
                <walletreport/> 
            </div>
                   
       
        </template>
      </actionmodalwide>
</template>

<script>
import walletreport from './report.vue'
import actionmodalwide from '../controls/actionmodalwide.vue'
import smallbutton from '../controls/button.vue'
export default {
    name:'reportpopup',
    components:{
        walletreport,
        actionmodalwide,
        smallbutton
    },
    methods:{
        openModal(){
            this.$refs.mymodal.openModal()
        }
    }
}
</script>

<style>

</style>