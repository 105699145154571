<template>
  <div class="general">
      <smallbutton toplabel="Message Templates" @click="Add()"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodalwide ref="mymodal" > 
        <template v-slot:body>
                <div class="rightjustified">
                    <smallbutton @click="close()" toplabel='X' buttonclass="buttonclose" />
                </div>
                <label class="titlelabel">Update Message Template</label>


                <div v-if="!inUpdate" class="margintop">
                    
                    <div v-for="result in lookupResults" :key="result.id" :value="result.id" >
                        <lookupresult :topLeft="result.name"      @click="select(result)"
                            :selected="result.id==selectedResultID" :inactive="!result.IsActive">
                    
                        </lookupresult>  
                    </div>
                    <div>
                        <smallbutton  @click="update()" toplabel="Update" />
                        <!-- <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/> -->
                    </div>
                </div>

                <div class="vertFlex margintop" v-if="inUpdate">
                    <label class="titlelabel">{{selectedResult.name}}</label>
                    <entry label="Subject" v-model="selectedResult.subject" />
                    <!-- <entry label="Plain Text" v-model="selectedResult.plain"/> -->
                    <!-- <entry label="SMS" v-model="selectedResult.sms"/> -->
                    <div v-if="showEdit">
                        <froala :tag="'textarea'" :config="config" v-model="selectedResult.html"  ></froala>
                    </div>
                    
                    <div class="fview" v-if="!showEdit" >
                        <froalaView v-model="htmlWithSample" />
                    </div>

                    <div class="horizFlexStretch">
                        <div>
                            <smallbutton  @click="toggleSample()" :toplabel="ToggleText" />
                        </div>
                        <div>
                            <smallbutton  @click="saveNew()" toplabel="Save" />
                            <smallbutton  @click="cancelupdate()" toplabel="Cancel" />
                        </div>
                    </div>
                </div>

                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
       
        </template>
      </actionmodalwide>
      
  </div>
</template>

<script>
 import VueFroala from 'vue-froala-wysiwyg';
//import FroalaEditor from 'froala-editor'


import smallbutton from '../controls/button.vue'
import actionmodalwide from '../controls/actionmodalwide.vue'
import lookupviewlayout from '../layouts/lookupviewlayout.vue'
import lookupresult from '../controls/lookupresult.vue'
import entry from '../controls/entry.vue'
import datamethods from '../../datamethods.js'

export default {
    name: 'messagetemplate',
    components:{
        smallbutton,
        actionmodalwide,
        lookupviewlayout,
        lookupresult,
        entry
    },
    mixins:[datamethods],
    data () {
        return {
            /* config:{
                key: window.FROALA_KEY,
                toolbarButtons: ["bold", "italic"]

            }, */
            config: {
                height:420,
                key:window.FROALA_KEY,
                events: {
                    'froalaEditor.initialized': function () {
                        console.log('initialized')
                    },
  
                },
                toolbarButtons:[['undo','redo','selectAll'],
                ['bold','italic','underline','subscript','superscript','fontFamily','fontSize','textColor','backgroundColor','clearFormatting'],
                ['alignLeft','alignCenter','alignRight','alignJustify'],
                ['insertLink','insertImage','insertVideo','emoticons','specialCharacters','insertHR'],['print']],
            
            },
            message:'',

            selectedResultID:0,
            lookupResults:[],
            selectedResult:{
                id:0,
                name:'',
                html:'',
                plain:'',
                sms:'',
                subject:'',
                IsActive:true,
                tokenList:[]
            },
            baseButtons:[['undo','redo','selectAll'],
                ['bold','italic','underline','subscript','superscript','fontFamily','fontSize','textColor','backgroundColor','clearFormatting'],
                ['alignLeft','alignCenter','alignRight','alignJustify'],
                ['insertLink','insertImage','insertVideo','emoticons','specialCharacters','insertHR'],['print']],
            addedButtons:[],
            inUpdate:false,
            showEdit:false,

        }
    },
    computed:{
        ToggleText(){
            if(this.showEdit){
                return "Show Example"
            }else{
                return "Hide Example"
            }
        },
        htmlWithSample(){
            var out = this.selectedResult.html
            console.log(out)
            out = out.replaceAll('[EmailAddress]','hannah@schoolvision.net')
            out = out.replaceAll('[FirstName]','Hannah')
            out = out.replaceAll('[LastName]','DeVore')
            out = out.replaceAll('[BadgeNumber]','123456')
            console.log(out)
            return out
        },
    },
    methods:{
        close(){
            this.$emit('refresh')
            this.$refs.mymodal.closeModal()
        },
        toggleSample(){
            this.showEdit=!this.showEdit
        },
        select(result){
            this.selectedResult=result;
            this.selectedResultID=result.id;
            
        },
        async Add(){
            this.getTemplates()
            
            
        },
        addToolBarInsert(bname,btext){
            FroalaEditor.DefineIcon('insert', {NAME: 'plus', SVG_KEY: 'add'});
            FroalaEditor.RegisterCommand(bname, {
                title: bname,
                focus: true,
                undo: true,
                refreshAfterCallback: true,
                callback: function () {
                this.html.insert(btext);
                }
            });
            var newButton = [bname]
            console.log('what im adding ' + bname + ' ' +  newButton + ' ' + btext)
            this.config.toolbarButtons.push(newButton)

        },
        async getTemplates(){
            
            var response = await this.callAPI(null,'templates')
            if(response.Successful){
                console.log(response)
                
                this.lookupResults=response.MessageTemplateList
                if(this.lookupResults.length>0){this.select(this.lookupResults[0])}
                this.AddToolbarButtons()
                this.$refs.mymodal.openModal()
            }else{
                //this.message = response.Message
            }

        },
        update(){
            this.inUpdate=true;
            this.showEdit=true;
        },
        cancelupdate(){
            this.inUpdate=false;
        },
        async saveNew(){
            let req = {MessageTemplate: this.selectedResult}
            
            var response = await this.callAPI(req,'savetemplate')
            if(response.Successful){
                console.log(response)
                
               // this.lookupResults=response.MessageTemplateList
                
                
            }else{
                //this.message = response.Message
            }
            this.inUpdate=false;
        },
        AddToolbarButtons(){
           // this.addToolBarInsert("insert this!!","[this]")

            for(let index = 0; index < this.selectedResult.tokenList.length; ++index){
                const t = this.selectedResult.tokenList[index]
                this.addToolBarInsert(t.name,t.tagtext)
            } 
             
            
        }
    }
}
</script>

<style>
.general{
    font-size:medium;
}
.smalltext{
    font-size:small;
}
.margintop{
    margin-top:20px;
}
.fview{
    background-color: white;
    padding:5px;
    text-align: left;
}
textarea{
    visibility: collapse;
    height: 0px;
}
</style>