<template>
<div>
    <smallbutton toplabel="Add Badge" @click="Add()" v-if="!isUpdate"/>
    <div class="editlink rightjustify">
        <linkbutton  toplabel="Edit Badge" @click="Edit()" v-if="isUpdate" />
    </div>
    
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="badgeform" >
                <label class="titlelabel">{{title}}</label>
                <formentry label="First Name" isrequired='True' v-model="person.FirstName" />
                <formentry label="Last Name" isrequired='True' v-model="person.LastName"  />
                <formentry  inputtype="tel"  label="Phone Number" @blur="leavePhone"  v-model="person.PhoneNumber1" />
                <formentry  inputtype="email" maxlength="100" label="Email Address" isrequired='True' v-model="person.EmailAddress" />

                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Location</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="person.locationId" @change="selectLocation()" required=true>
                        <option v-for="location in locations" :key="location.id" :value="location.id" >
                            {{location.name}}
                        </option>
                    </select>
                </div>
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Role</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="person.personRoleId" required=true>
                        <option v-for="role in roles" :key="role.id" :value="role.id" >
                            {{role.name}}
                        </option>
                    </select>
                </div>
                <div class="entry" v-if="!isUpdate">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Class</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="person.personRoleIdClass">
                        <option v-for="c in classes" :key="c.id" :value="c.id" >
                            {{c.name}}
                        </option>
                    </select>
                </div>

                <formentry    label="Badge #" isrequired='True' v-model="person.badgeNumber" minlength=6 />
                <div class="rightjustify">
                    <label class="smalltext nomargin">min 6 digits. Pad shorter numbers with leading 0's.</label>
                </div>
                <br>
                <formentry  inputtype="password"  label="Password" isrequired='True' v-model="person.password" v-if="!isUpdate"/>
                <formentry  inputtype="password"  label="Confirm Password" isrequired='True' v-model="person.confirmPassword" v-if="!isUpdate"/>
                
                <div class="entry" v-if="!isUpdate">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Add ECommerce Wallet and Account</label>
                    </div>
                    <div class="entrycheck">
                        <input type="checkbox" v-model="person.addWalletAndAccount"/>
                    </div>
                </div>
                <div class="entry" v-if="!isUpdate">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Is Wallet/Account Admin</label>
                    </div>
                    <div class="entrycheck">
                        <input type="checkbox" v-model="person.addAsWalletAdmin" :disabled="!person.addWalletAndAccount"/>
                    </div>
                </div>
                <div class="entry" v-if="!isUpdate">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Send Invite</label>
                    </div>
                    <div class="entrycheck">
                        <input type="checkbox" v-model="person.sendInvite"/>
                    </div>
                </div>
                <div>
                    <smallbutton  @click="addWalletAccount()" toplabel="Setup E-Commerce" v-if="isUpdate"/>
                    <smallbutton  @click="resetPassword()" toplabel="Send Reset Password Email" v-if="isUpdate"/>
                </div>
                

                <smallbutton  @click="saveNew()" toplabel="Save" />
                <smallbutton  @click="close()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
          </form>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
export default {
    name:'newbadge',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton
    },
    props:{
        badge:{
            type:Object,
            default:null
        },
        isUpdate:{
            type:Boolean,
            default:false
        }    
        
    },
    mixins:[datamethods],
    data(){
        return{
            person:{},
            message:'',
            roles:[],
            classes:[],
            tmpresp:{}
        }
    },
     computed:{
        ...mapFields([
            'locations'
        ]),
        title(){
            if(this.isUpdate){ return "Update Badge"}
            else { return "Add Badge"}
        }
     },
    methods:{
        async Add(){
            this.person={
                locationId:null,
                emailAddress:null,
                firstName:null,
                lastName:null,
                phoneNumber:null,
                personRoleId:null,
                personRoleIdClass:null,
                badgeNumber:null,
                password:null,
                confirmPassword:null,
                addWalletAndAccount:false,
                addAsWalletAdmin:false,
                sendInvite:true,

            }
            this.message=''
            this.$refs.mymodal.openModal()
        },
        async Edit(){
            this.person = {
                locationId:this.badge.locationId,
                personId:this.badge.personId,
                EmailAddress:this.badge.emailAddress,
                FirstName:this.badge.firstName,
                LastName:this.badge.lastName,
                PhoneNumber1:this.badge.phoneNumber,
                personRoleId:this.badge.personRoleId,
                personRoleIdClass:null,
                badgeNumber:this.badge.badgeNumber,
                password:null,
                confirmPassword:null,
                addWalletAndAccount:false,
                addAsWalletAdmin:false,
                sendInvite:false,
                updateBadgeId:this.badge.badgeId
            }
            this.$refs.mymodal.openModal()
           /*  //use the prop for the data
            let req = {
                badgeId:this.badge.badgeId,
                personId:this.badge.personId
            }
            
            this.pleaseWait=true
            var response = await this.callAPI(req,'badgeonly')
            if(response.Successful){
                console.log(response)
                if(response.id<0){
                    this.message=response.message
                }else{
                   this.person = response.person
                    this.$refs.mymodal.openModal()
                }
                
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false; */

        },
        close(){
            this.$emit('refresh')
            this.$refs.mymodal.closeModal()
        },
        async saveNew(){
            if(this.$refs.badgeform.checkValidity()==false){return}
            let req = this.person
            
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'badgesetup')
            if(response.Successful){
                console.log(response)
                if(response.id<0){
                    this.message=response.message
                }else{
                    //this.message="Congrats! You added a badge."
                    this.$refs.mymodal.closeModal()
                }
                
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        async addWalletAccount(){
            
            let req = this.person
            
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'badgewalletaccount')
            if(response.Successful){
                console.log(response)
                if(response.id<0){
                    this.message=response.message
                }else{
                    this.message="You successfully setup E-Commerce for this badge"
                    //this.$refs.mymodal.closeModal()
                }
                
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        async resetPassword(){
            let req = this.person
            
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'forgotpass')
            if(response.Successful){
                console.log(response)
                if(response.id<0){
                    this.message=response.message
                }else{
                    this.message="Reset Password Email Sent"
                    //this.$refs.mymodal.closeModal()
                }
                
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        leavePhone(){

            this.person.PhoneNumber1=this.$options.filters.formatPhone(this.person.PhoneNumber1);
            
            
        },
        async getRoles(){
            //setup request
            let req = {
                locationId:this.person.locationId
            }
            //call api
            var response = await this.callAPI(req,'rolelist')
            //handle response
            if(response.Successful){
                this.roles=response.PersonRoleList
                this.roles.shift()
            }else{
                this.message = response.Message
            }
        },
        async getClasses(){
            //setup request
            let req = {
                locationId:this.person.locationId
            }
            //call api
            var response = await this.callAPI(req,'classlist')
            //handle response
            if(response.Successful){
                this.classes=response.PersonClassList
            }else{
                this.message = response.Message
            }
        },
        
        selectLocation(){
            this.getClasses();
            this.getRoles();
        }
    },
    mounted(){
       
     
        
    }
}
</script>

<style>
form{
    font-size:large;
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.rightjustify{
    text-align: right;
    
}
.nomargin{
    margin:0px;
}
.editlink{
    font-size: large;
}
</style>





























