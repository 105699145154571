<template>
<div>
    <linkbutton toplabel="View Documents" @click="GetDocuments()" class="alertlink"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form >
                <div class="history">
                        <label class="studentname" >Documents</label>
                        <div v-if="pleaseWait"><label class="pleasewait">Please wait...</label></div>
                        <div >
                            <div class="docDiv">
                                <div v-for="document in DocumentList" :key="document.id" :value="document.id" class="documentitems" >
                                    <img src="@/assets/check.png" class="docimg" v-if="document.fileName!=null"/>
                                    <div class="vertFlex col2" >
                                        <div class="horizFlex">
                                            <label class="doctitle" >{{document.typeName}}</label>
                                            <label class="required" v-if="document.required" >*</label>
                                        </div>
                                        <!-- <linkbutton :href="document.fileName" target="_blank" :toplabel="document.fileDisplayName"/> -->
                                        <a class="timetext" :href="document.fileName" target="_blank" v-if="document.canView">{{document.fileDisplayName }} </a>
                                    </div>
                                    
                                    <div class="vertFlex col3">
                                        <button class="docbutton" type="button"  @click="openDocImage(document)">
                                            <img src="@/assets/file-document-edit-outline.png" class="docimg2"/>
                                        </button>
                                        <linkbutton :class="signlinkClass(document)"  toplabel="sign" @click="openSignModal(document.fileName)" v-if="document.fileName!=null && document.canSign"/>
                                    </div>      
                                </div>
                            </div>
                            
                            <label class="required"  >* required</label>
                            <imagealldoc ref="imgdoc"   :allowFileSelect="allowFileSelect" :allowCapture="allowCapture" @saveimg="saveimg_document"  :showImage='showImagesInCapture' />
                            <signaturemodal ref="signdoc" @savesign="savesign_document"/>
                        </div>
   
                </div>
                <div>
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                </div>
               
          </form>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import linkbutton from '../controls/linkbutton.vue'
import format from '../../format.js'
import imagealldoc from '../controls/ImageAllDoc.vue'
import signaturemodal from '../controls/signaturemodal.vue'
export default {
    name:'documentadmin',
    components:{
        smallbutton,
        actionmodal,
        linkbutton,
        imagealldoc,
        signaturemodal
    },
    props:{
        record:{
            type:Object,
            
        },
        
    },
    mixins:[datamethods, format],
    data(){
        return{
            imageBasePath:'',
            DocumentList:[],
            showImagesInCapture:false,
            allowFileSelectIfNoCapture:true,
            allowCapture:true,
            allowFileSelect:true,
        }
    },
    computed:{
       
    },
    methods:{
        signlinkClass(doc){
            if(doc.requireSignature){return "signlinkrequired"}
            else{return "signlink"}
        },
        async GetDocuments(){
            this.pleaseWait=true
            console.log('docs')
            var basepath = this.imageBasePath 
            console.log('base ' + basepath)
            let req = {
                personId:this.record.personId,
            }

            var response = await this.callAPI(req,'documents')
            //handle response
            if(response.Successful){
                this.DocumentList=response.DocumentList
                this.$refs.mymodal.openModal()
                this.DocumentList.forEach(myFunction2)
                
                function myFunction2(item, index, arr) {
                    let acct=arr[index]
                    let newpath = basepath + acct.fileName
                    console.log('b ' + acct + ' ' + newpath)
                    acct.fileName = newpath
                } 
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false
        },
        
        saveimg_document(file,url,typeId){
            this.storeDocument("doc", file, file.name,typeId)
        },
        async savesign_document(file,url,docname){
            console.log('savesign')
             var pId=this.record.personId
            var postParams = new FormData();

            postParams.append("sign",file,name);
            postParams.append('docname',docname);

            var response = await this.callAPI(postParams,'savesignature')
            //handle response
            if(response.Successful){
                this.GetDocuments();  
            }else{
                this.responseMessage = response.Message
            }
        },
        async storeDocument(filename,file, name,typeId){
             var pId=this.record.personId
            var postParams = new FormData();

            postParams.append(filename,file,name);
            
            postParams.append('personId',pId);
            postParams.append('typeId',typeId);

            var response = await this.callAPI(postParams,'savedocument')
            //handle response
            if(response.Successful){
                this.GetDocuments();  
            }else{
                this.responseMessage = response.Message
            }
             
        },
        openDocImage(doc){
              console.log(doc.typeId)
            this.$refs.imgdoc.referenceID=doc.typeId
           this.$refs.imgdoc.allowFileSelect=true
            this.$refs.imgdoc.allowCapture=true
            this.$refs.imgdoc.openModal() 
        },
        openSignModal(docname){
            this.$refs.signdoc.referenceDoc=docname
            this.$refs.signdoc.openModal()
        },
        async getDocumentImageBasePath(){
            //call api
            var response = await this.callAPI(null,'imagepath')
            //handle response
            if(response.Successful){
                this.imageBasePath = response.StringResponse
            }else{
                this.responseMessage = response.Message
            }
        },


        /* async View(){
             let req = {
                locationId:-1,
                badgeId:this.record.badgeId,
                
            }
            
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'alertlog')
            if(response.Successful){
                this.log=response.AlertLogList
                this.$refs.mymodal.openModal()
  
                
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
            
        }, */
        
        
    },
    mounted(){
      this.getDocumentImageBasePath()
      
    }
}
</script>

<style>
.history{
    margin-top:15px;
    display:flex;
    flex-direction: column;
    align-items: center;
}
form{
    font-size:large;
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.rightjustify{
    text-align: right;
    
}
.nomargin{
    margin:0px;
}
.alertlink{
    font-size:large;
}
.logitem{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size:medium;
    border:1px solid darkgray;
    margin:5px;
    padding:2px;
}

.docDiv{
    overflow-x:auto;
    overflow-y:scroll;
    height:370px;
}
.documentitems{
    display:grid;
    grid-template-columns:1fr 3fr 1fr;
    text-align:left;
    font-size:medium;
    margin:10px;
}
.doctitle{
    font-weight:bold;

}
.docbutton{
    height:20px;
    width:30px;
    border:none;
    background-color:transparent;
}
.docimg{
    width:20px;
    background-color:transparent;
    margin:0px;
}
.docimg2{
    width:20px;
    background-color:transparent;
    
}
.col2{
    grid-column: 2;
}
.col3{
    grid-column:3;
    padding-right:10px;
   
}
.required{
    color:red;
}
.signaturebox{
    height:200px;
    width:500px;
    margin:auto;
    background-color:white;
    border:1px solid black;
}
.signlink{
    margin-top:10px;
}
.signlinkrequired{
    margin-top:10px;
    color:red;
}
</style>





























