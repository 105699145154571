import Vue from 'vue'
import App from './App.vue'
import {store} from './store/store'

import GSignInButton from 'vue-google-signin-button'
Vue.use(GSignInButton)

Vue.config.productionTip = false

//froala
require('froala-editor/js/froala_editor.pkgd.min.js')
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/css/plugins/emoticons.min.css'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/js/plugins/special_characters.min.js'
import 'froala-editor/css/plugins/special_characters.min.css'

import 'froala-editor/js/third_party/spell_checker.min.js'
import 'froala-editor/css/third_party/spell_checker.min.css'

import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/css/plugins/video.min.css'

import 'froala-editor/js/plugins/print.min.js'

import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)

new Vue({
  store,
  render: function (h) { return h(App) },
}).$mount('#app')
