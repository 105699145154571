<template>
<div class='general'>
    <linkbutton toplabel="Update Start Date" @click="Start()" class="alertlink"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form >
                <div class="rightjustified">
                    <smallbutton @click="$refs.mymodal.closeModal()" toplabel='X' buttonclass="buttonclose" />
                </div>
                <label class="titlelabel">{{title}}</label>
                <div>
                    <label for="" class="pleasewait" v-if="pleaseWait">Please Wait...</label>
                </div>
                <div  class="mainform">
                    <div class="col">
                        
                       
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Start Date</label>
                            </div>
                            <input type="date" class="entrySelect"  v-model="thisStartDate" />
                        </div>
  
                    </div>
  
                </div>
                <div >
                    <smallbutton  @click="save()" toplabel="Save" />
                    <smallbutton  @click="cancel()" toplabel="Cancel" />
                </div>

                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
          </form>

            
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'

export default {
    name:'updatestartdate',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton

    },
    props:{
        person:{
            type:Object,
        }
    },
    mixins:[datamethods,format],
    data(){
        return{
            title:'Update Start Date',
            message:'',
            

            pleaseWait:false,
            thisStartDate:this.person.startDate    
        }
    },
    computed:{
      StartDate(){
          if(this.thisStartDate==undefined){
              return this.$options.filters.formatDateForInputToday()
          }else{
              return this.$options.filters.formatDateForInput(this.thisStartDate)
          }
      }
    },
    methods:{
        
       
        async Start(){
           // this.pleaseWait=true
            this.message=''
            this.$refs.mymodal.openModal()
            
        },
        cancel(){
            this.$refs.mymodal.closeModal();
        },
        async save(){
      
            
            let req = {
                person:{
                    id:this.person.personId,
                    hireDate:this.thisStartDate
                }
            }
            this.message=""
            this.pleaseWait=true;
            var response = await this.callAPI(req,'updatestartdate')
            if(response.Successful){
                this.thisStartDate=this.$options.filters.formatDateOutOfInput(this.thisStartDate)
                this.message = "Start Date Updated"
                this.$emit('updated',this.person.personId,this.thisStartDate)
                this.$refs.mymodal.closeModal();
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;

        },
    
        
        
       
    },

}
</script>

<style>
.mainform{
    
}
.general{
    font-size:medium;
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.imgbutton{
    background-color:var(--primary-color);
    color:var(--light-text-color);
    border: none;
    border-radius:10px;
    min-width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
  text-decoration: none;
  position:relative;
}
.imgbutton:hover{
    background-color:var(--highlight-color);
  cursor:pointer;
}
.icondiv{
    background-color:blue;
    background:transparent;
}
.icon{
    background:transparent;
    max-width: 250px;;
}
.col{
    padding:20px;
}
.entrycheck{
    text-align: left;
    width:100%;
    margin-top:7px;
    margin-bottom:7px;
}
.entrymulti{
    visibility: visible;
    font-size:inherit;
    margin:0px 5px 0px 5px;
    
    text-align:left;
    resize:none;
    min-height:50px;
    width:100%;
}
</style>





























