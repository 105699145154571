<template>
<div class='general'>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form >
                <div class="rightjustified">
                    <smallbutton @click="$refs.mymodal.closeModal()" toplabel='X' buttonclass="buttonclose" />
                </div>
                <label class="titlelabel">{{title}}</label>
                <div>
                    <label for="" class="pleasewait" v-if="pleaseWait">Please Wait...</label>
                </div>
                <div  class="mainform">
                    <div class="col">
                        
                        <label >Please select which alert to send</label>
                        <div>
                            <select name="" id="" class='filtertemplate' v-model="currentItem.badgeAlertTemplateId" >
                                <option v-for="template in alertTemplateList" :key="template.id" :value="template.id" >
                                    {{template.name}}
                                </option>
                            </select>
                        </div>
                       
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Applies to Location</label>
                            </div>
                            <select name="" id="" class='entrySelect' v-model="currentItem.locationId">
                                <option v-for="location in locations" :key="location.id" :value="location.id" >
                                    {{location.name}}
                                </option>
                            </select>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Applies to Department</label>
                            </div>
                            <select name="" id="" class='entrySelect' v-model="currentItem.departmentId" >
                                <option v-for="department in departments" :key="department.id" :value="department.id" >
                                    {{department.name}}
                                </option>
                            </select>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Applies to Role</label>
                            </div>
                            <select name="" id="" class='entrySelect' v-model="currentItem.personRoleId" >
                                <option v-for="role in personRoleList" :key="role.id" :value="role.id" >
                                    {{role.name}}
                                </option>
                            </select>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">All Employees</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="currentItem.sendToAllEmployees" />
                            </div>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">All Students</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="currentItem.sendToAllStudents" />
                            </div>
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">All Visitors</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="currentItem.sendToAllVisitors" />
                            </div>
                        </div>
                        <formentry label="Badge ID"  v-model="currentItem.badgeId" />
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Override Effective Date</label>
                            </div>
                            <input type="date" class="entrySelect"  v-model="currentItem.overrideEffectiveDateTime" />
                        </div>
                        <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Override Expiration Date</label>
                            </div>
                            <input type="date" class="entrySelect"  v-model="currentItem.overrideExpirationDateTime" />
                        </div>
                        <hr>
                         <div class="entry">
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Override Can Be Dismissed</label>
                            </div>
                            <div class="entrycheck">
                            <input type="checkbox"  v-model="currentItem.overrideCanBeDismissed" />
                            </div>
                        </div>

                    
                    </div>
  
                </div>
                <div >
                    <smallbutton  @click="save()" toplabel="Send" />
                    <smallbutton  @click="cancel()" toplabel="Cancel" />
                </div>

                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
          </form>

            
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import formentry from '../controls/entry.vue'


export default {
    name:'sendalert',
    components:{
        smallbutton,
        actionmodal,
        formentry,


    },
    props:{

        
    },
    mixins:[datamethods,format],
    data(){
        return{
            title:'Send Alert',

            currentItem:{
                badgeAlertTemplateId:-1,
                locationId:-1,
                departmentId:-1,
                personRoleId:-1,
                badgeId:null,
                sendToAllEmployees:false,
                sendToAllStudents:false,
                sendToAllVisitors:false,
                overrideEffectiveDateTime:null,
                overrideExpirationDateTime:null,
                overrideCanBeDismissed:false
            },
            message:'',
            itemList:[],
            departments:[],
            locations:[],
            personRoleList:[],
            alertTemplateList:[],

            pleaseWait:false,
            multirows:2
        }
    },
    computed:{
      
    },
    methods:{
        
       


      
        async Start(){
            this.pleaseWait=true
            this.getPersonRoleList();
            this.getDepartments();
            this.getLocations();
            this.getAlertTemplates();
            this.$refs.mymodal.openModal()
            
        },
        cancel(){
            this.$refs.mymodal.closeModal();
        },
        async save(){
            if(this.currentItem.badgeAlertTemplateId==-1){
                this.message="Please select an alert template"
                return
            }
            let req = this.currentItem
            this.message=""
            this.pleaseWait=true;
            var response = await this.callAPI(req,'sendbadgealert')
            if(response.Successful){
                
                this.message = "Alert Sent"
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;

        },
    
        
        
        async getPersonRoleList(){
            this.message=""
            //call api
            var response = await this.callAPI(null,'rolelist')
            //handle response
            this.tmpresp = response
            if(response.Successful){
                this.personRoleList=response.PersonRoleList
            }else{
                this.message = response.Message
            }
        },
        async getDepartments(){
            this.message=""
            //call api
            var response = await this.callAPI(null,'departmentlist')
            //handle response
            this.tmpresp = response
            if(response.Successful){
                this.departments=response.DepartmentList
            }else{
                this.message = response.Message
            }
        },
        async getLocations(){
            this.message=""
            //call api
            var response = await this.callAPI(null,'locationlist')
            //handle response
            this.tmpresp = response
            if(response.Successful){
                this.locations=response.institutionLocationList
            }else{
                this.message = response.Message
            }
        },
         async getAlertTemplates(){
            var response = await this.callAPI(null,'badgealerttemplatelist')
            if(response.Successful){

                this.alertTemplateList=response.BadgeAlertTemplateList
                this.pleaseWait=false
            }else{
                //this.message = response.Message
            }
        },
    },

}
</script>

<style>
.mainform{
    
}
.general{
    font-size:medium;
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.imgbutton{
    background-color:var(--primary-color);
    color:var(--light-text-color);
    border: none;
    border-radius:10px;
    min-width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
  text-decoration: none;
  position:relative;
}
.imgbutton:hover{
    background-color:var(--highlight-color);
  cursor:pointer;
}
.icondiv{
    background-color:blue;
    background:transparent;
}
.icon{
    background:transparent;
    max-width: 250px;;
}
.col{
    padding:20px;
}
.entrycheck{
    text-align: left;
    width:100%;
    margin-top:7px;
    margin-bottom:7px;
}
.entrymulti{
    visibility: visible;
    font-size:inherit;
    margin:0px 5px 0px 5px;
    
    text-align:left;
    resize:none;
    min-height:50px;
    width:100%;
}
</style>





























